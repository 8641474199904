import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ConfigProvider, Breadcrumb, Button, Form, Input, Spin } from 'antd'

import axiosInstance from '../utils/axiosInstance'
import { authslice } from '../store/auth/authSlice'

import styles from './styles/Login.module.css'

function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null)
  const [error, showError] = useState(false)
  const { setUser } = authslice.actions

  const onFinish = async (values) => {
    console.log('Success:', values)
    const reqObj = {
      ...values,
    }

    try {
      showError(false)
      setLoading(true)

      const response = await axiosInstance.post('/api/v1/admin/login', reqObj)
      console.log(response.data)
      dispatch(
        setUser({ token: response.data.token, user: response.data.data.user })
      )
      // You can handle the response data here
      // router.push('/')
      setLoading(false)
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      showError(true)
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    // console.log(auth?.token)
    if (auth?.token) {
      navigate('/home')
    }
  }, [auth])

  return (
    <div className={styles.container}>
      <div className={styles.theFormContainer}>
        <h1 className={styles.title}>Grow or die</h1>
        <p>Welcome to admin login of Growdie</p>
        <Form
          ref={formRef}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input a valid email!',
              },
            ]}
          >
            <Input placeholder="EMAIL" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password placeholder="PASSWORD" />
          </Form.Item>
          {error && (
            <Form.Item name="err">
              <p style={{ margin: 0, color: 'red' }}>
                Incorrect email or password
              </p>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              disabled={loading}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Login
