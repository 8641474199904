import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import { Button, List, Tag, Typography, Modal, Select, Alert } from 'antd'
import { Input, Space } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

import classes from './styles/Referrals.module.css'

const { Paragraph } = Typography

function Referrals() {
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)

  const [loading, setLoading] = useState(false)
  const [referralCode, setReferralCode] = useState('')
  const [emailToSend, setEmailToSend] = useState('')
  const [myReferralsList, setMyReferralsList] = useState('')
  const [referralCreatedFor, setReferralCreatedFor] = useState('')
  const [errorText, setRrrorText] = useState('')
  const [organizations, setOrganizations] = useState([])

  // console.log(auth?.user)
  // const time = Date.now()
  // console.log(time)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }

  const createR = async (values) => {
    console.log('Success:', values)
    const reqObj = {
      ...values,
    }

    setRrrorText('')

    if (!referralCreatedFor) {
      setRrrorText('Please select an item from dropdown')
      return
    }

    try {
      setLoading(true)
      setReferralCode('')

      const response = await axiosInstance.post(
        `/api/v1/referral/create?for=${referralCreatedFor}`
      )
      console.log(response.data)
      setReferralCode(response.data.newReferral?.referralCode)
      // You can handle the response data here
      // router.push('/')
      setLoading(false)
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      setLoading(false)
    }
  }

  const sendEmailReferral = async () => {
    if (!emailToSend) {
      return
    }

    const reqObj = {
      email: emailToSend,
      referral: referralCode,
    }

    try {
      setLoading(true)

      const response = await axiosInstance.post(
        '/api/v1/referral/sendReferral',
        reqObj
      )
      console.log(response.data)

      showModal()
      setLoading(false)
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      setLoading(false)
    }
  }

  const myReferrals = async () => {
    try {
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 1000)) // TODO: remove on production

      const response = await axiosInstance.get(
        `/api/v1/referral/getMyReferrals`
      )
      setMyReferralsList(response.data?.referrals)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getMyInfo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/me`)

      // Check the admin organizations
      if (
        response?.data?.user?.organizations &&
        response?.data?.user?.organizations.length > 0
      ) {
        const org = response.data.user.organizations
        // console.log(org)

        // store to render at JSX
        org.forEach((element) => {
          setOrganizations((prevElements) => [
            ...prevElements,
            {
              value: element._id,
              label: element.name,
            },
          ])
        })
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleChange = (value) => {
    //
    setReferralCreatedFor(value)
  }

  useEffect(() => {
    myReferrals()
    getMyInfo()
  }, [])

  return (
    <Layout>
      <Modal
        title="Referral status"
        open={isModalOpen}
        footer={null}
        closeIcon={null}
      >
        <p>Referral has been sent via email.</p>
        <Button type={'primary'} onClick={() => window.location.reload()}>
          Ok
        </Button>
      </Modal>
      <div>
        <h1>Manage referrals/Growdie code</h1>
        <div>
          <p>Choose organization for which the growdie code is for</p>
          <Select
            placeholder="Please select club or business"
            style={{
              width: 250,
            }}
            onChange={handleChange}
            options={organizations}
          />{' '}
          <Button
            type="primary"
            onClick={createR}
            loading={loading}
            disabled={organizations.length == 0} // must be organization for creating referrals
          >
            Create referrals
          </Button>
        </div>
        {errorText && (
          <Alert
            message={errorText}
            type="error"
            style={{ width: 400, marginTop: 20 }}
          />
        )}
        <br />
        <br />
        {referralCode && (
          <div>
            <p>Generated referral is:</p>
            <Paragraph copyable>{referralCode}</Paragraph>
            <p>
              If you want to send automated email enter the email and click send{' '}
            </p>
            <Space.Compact
              style={{
                width: '80%',
              }}
            >
              <Input
                placeholder="Email"
                onChange={(e) => setEmailToSend(e.target.value)}
              />
              <Button
                type="primary"
                loading={loading}
                onClick={sendEmailReferral}
              >
                Send Email
              </Button>
            </Space.Compact>
          </div>
        )}
        <div>
          <h2>Referrals history</h2>
          <List
            size="large"
            bordered
            dataSource={myReferralsList}
            renderItem={(item) => (
              <List.Item>
                <div className={classes.howListContainer}>
                  <div className={classes.howListItemColumn}>
                    {' '}
                    <Tag bordered={false}>{item.referralCode}</Tag>
                    {Date.now() > item.expiresAt && (
                      <Tag
                        color="red"
                        style={{ fontSize: 8, padding: 1, lineHeight: 1 }}
                      >
                        Expired
                      </Tag>
                    )}
                  </div>
                  <div className={classes.usedColumn}>
                    {' '}
                    {item.used ? (
                      <Tag color="red">Used</Tag>
                    ) : (
                      <Tag color="green">Not used</Tag>
                    )}
                  </div>
                  <div className={classes.howListItemColumn}>
                    <Tag>
                      Created at:{' '}
                      {moment(item.createdAt).format('MMMM DD, YYYY h:mm:ss a')}
                    </Tag>
                  </div>{' '}
                  <div className={classes.howListItemColumn}>
                    {item.createdFor ? (
                      <Tag>Created for: {item.createdFor.name} </Tag>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={classes.usedByColumn}>
                    {item.usedBy.length > 0 ? (
                      <span
                        style={{ color: '#1677ff', cursor: 'pointer' }}
                        onClick={() =>
                          navigate(`/member/${item.usedBy[0]._id}`)
                        }
                      >
                        Used by
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Referrals
