import { configureStore } from '@reduxjs/toolkit'
import { authslice } from './auth/authSlice'

// config the store
const store = configureStore({
  reducer: {
    auth: authslice.reducer,
  },
})

// export default the store
export default store

// export the action
export const iconAction = authslice.actions
